
import { Options, Vue } from "vue-class-component";
import { Watch } from "vue-property-decorator";
import { isoCode } from "./utils/LanguagePacks/types/isoCodes";

@Options({})
export default class App extends Vue {
  @Watch("$route.path")
  onPathChange(newPath: string) {
    if (newPath != "/" && this.language) {
      console.log("working....");
      this.$ml.changeLanguage(this.language);
    }
  }

  get language() {
    return (this.$route?.query.language as isoCode) ?? "";
  }
}
