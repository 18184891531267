import { isoCode } from "./LanguagePacks/types/isoCodes";
import { LanguagePack } from "./LanguagePacks/types/LanguagePack";

import en from "./LanguagePacks/en";
import nl from "./LanguagePacks/nl";

const languages = {
  en,
  nl,
};

export class Multilanguage {
  public pack: LanguagePack;

  constructor() {
    this.pack = en;
  }

  changeLanguage(code: isoCode) {
    this.pack = languages[code];
  }
}
