import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, vModelText as _vModelText, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-56b4a149"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "login" }
const _hoisted_2 = { class: "centered" }
const _hoisted_3 = { class: "subtitle" }
const _hoisted_4 = { for: "email-input" }
const _hoisted_5 = { for: "password-input" }
const _hoisted_6 = ["value"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Header = _resolveComponent("Header")!
  const _component_ErrorList = _resolveComponent("ErrorList")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_Header),
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("h1", null, [
        _createTextVNode(_toDisplayString(_ctx.lang.header.title) + " ", 1),
        _createElementVNode("span", _hoisted_3, _toDisplayString(_ctx.lang.header.subtitle), 1)
      ]),
      _createElementVNode("div", null, [
        _createElementVNode("form", {
          onSubmit: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.checkForm && _ctx.checkForm(...args)))
        }, [
          _createElementVNode("label", _hoisted_4, _toDisplayString(_ctx.lang.form.emailField), 1),
          _withDirectives(_createElementVNode("input", {
            type: "text",
            name: "email",
            id: "email-input",
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.email) = $event))
          }, null, 512), [
            [_vModelText, _ctx.email]
          ]),
          _createElementVNode("label", _hoisted_5, _toDisplayString(_ctx.lang.form.passwordField), 1),
          _withDirectives(_createElementVNode("input", {
            type: "password",
            name: "password",
            id: "password-input",
            "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.password) = $event))
          }, null, 512), [
            [_vModelText, _ctx.password]
          ]),
          _createElementVNode("input", {
            class: "submit",
            type: "submit",
            value: _ctx.lang.form.submitButton
          }, null, 8, _hoisted_6)
        ], 32)
      ])
    ]),
    _createVNode(_component_ErrorList, { errorList: _ctx.notificationList }, null, 8, ["errorList"])
  ]))
}