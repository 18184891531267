import { LanguagePack } from "./types/LanguagePack";

const languagePack: LanguagePack = {
  isoCode: "nl",
  texts: {
    generic: {
      errorPrefix: "Fout:",
    },
    adminDashboard: {
      header: {
        title: "Admin",
        subtitle: "dashboard",
      },
      logout: "Uitloggen",
      form: {
        caNumberField: "CA-nummer:",
        submitButton: "Zoeken",
      },
      result: {
        back: "Terug naar zoeken",
        resultsHeader: "Resultaten:",
        display: {
          header: "Number:",
          notRenewableDescription:
            "Dit kan omdat het actieve fouten bevat, of dat het al wordt verwerkt.",
          notRenewableHeader: "Deze RMA kan niet worden verlengd",
          renewButton: "Verzoek vernieuwen",
          printButton: "Print and Post Label",
          cancelButton: "Cancel Request",
          renewableHeader: "Deze RMA is hernieuwbaar",
          renewed: {
            prefix: "Rma is vernieuwd, u wordt teruggestuurd naar zoeken in",
            suffix: "seconden.",
          },
          canceledRequest: "Request geanuleerd!",
          printRequest: "Drukaf aanvraag verstuurd!",
        },
      },
    },
    login: {
      header: {
        title: "Administrator",
        subtitle: "login",
      },
      form: {
        emailField: "E-mail",
        passwordField: "Wachtwoord",
        submitButton: "Inloggen",
      },
    },
    existing: {
      header: {
        title: "Verzoek",
        subtitle: "bestaat al!",
      },
      description:
        "We hebben al een geldige UPS afhaaldatum! U kunt deze pagina nu sluiten.",
    },
    existingEditable: {
      header: {
        title: "Verzoek",
        subtitle: "bestaat al!",
      },
      description:
        "We hebben al een geldige UPS Afhaaldatum, maar je kunt het formulier nog steeds opnieuw indienen als je de datum wilt wijzigen.",
      button: "Gewijzigde afhaaldatum",
    },
    received: {
      header: {
        title: "Verzoek",
        subtitle: "ontvangen!",
      },
      description:
        "We hebben alles wat we nodig hebben! U ontvangt een bevestiging per e-mail. U kunt deze pagina nu sluiten.",
    },
    restricted: {
      header: {
        title: "Ongeldig",
        subtitle: "verzoek!",
      },
      description:
        "Dit verzoek is verlopen of was in de eerste plaats ongeldig.",
      adminLogin: {
        prefix: "Bent u een beheerder die probeert in te loggen? Klik op",
        suffix: "hier",
      },
    },
    home: {
      header: {
        title: "Boek een",
        subtitle: "UPS Pickup",
      },
      form: {
        rmaHeader: "Uw RMA:",
        rmaDateField: "Afhaaldatum *",
        dateInput: {
          dayNames: [
            "Sunday",
            "Monday",
            "Tuesday",
            "Wednesday",
            "Thursday",
            "Friday",
            "Saturday",
          ],
          monthNames: [
            "januari",
            "februari",
            "maart",
            "april",
            "mei",
            "juni",
            "juli",
            "augustus",
            "september",
            "oktober",
            "november",
            "december",
          ],
        },
        dayPart: {
          header:
            "We kunnen proberen de afhaling te beperken tot een dagdeel. (De hele dag wordt geselecteerd als het gekozen dagdeel niet beschikbaar is)",
          allday: "All Day",
          morning: "morgen",
          afternoon: "Middag",
          subtext:
            "Dit is geen garantie, als de geselecteerde tijd niet beschikbaar is, zullen we terugvallen op de hele dag.",
        },
        pickupToday: {
          header:
            "We kunnen proberen om de afhaling voor vandaag te boeken, als dit niet lukt gebruiken we de geselecteerde dag en het dagdeel.",
          checkbox: "Probeer vandaag nog op te halen",
          subtext:
            "Dit is geen garantie, als we vandaag niet kunnen afhalen, vallen we terug op de geselecteerde datum.",
        },
      },
      loading: {
        header: {
          title: "Laden",
          subtitle: "verzoek ...",
        },
        description:
          "Als dit meer dan een minuut duurt, ververs dan de pagina.",
      },
    },
  },
};
export default languagePack;
