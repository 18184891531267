import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-5ca5b41c"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "error-list" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.errorList, (error) => {
      return (_openBlock(), _createElementBlock("div", {
        key: error.id,
        class: _normalizeClass(["notification", error.type])
      }, [
        _createElementVNode("h1", null, _toDisplayString(_ctx.lang.errorPrefix) + ":", 1),
        _createElementVNode("span", null, _toDisplayString(error.message), 1)
      ], 2))
    }), 128))
  ]))
}