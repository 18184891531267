
import { Options, Vue } from "vue-class-component";

import Header from "@/components/Header.vue";
import RMADisplay from "@/components/RMADisplay.vue";
import ErrorList from "@/components/ErrorList.vue";
import { Rma } from "@/types/rma";

import { addNotification, Notification } from "@/utils/NotificationHandler";

@Options({
  components: {
    Header,
    RMADisplay,
    ErrorList,
  },
})
export default class AdminDashboard extends Vue {
  private rma: Rma = {} as Rma;
  private loading = true;
  private rmaCaNumber = "";
  private index = 0;
  private notificationList: Notification[] = [];
  private token = "";

  get lang() {
    return this.$ml.pack?.texts.adminDashboard;
  }

  mounted() {
    if (localStorage.token) this.token = localStorage.token;
    else this.$router.push("login");
  }

  checkForm(event: Event) {
    event.preventDefault();

    this.fetchRma(this.rmaCaNumber);
  }

  notificationPassthrough(notification: Notification) {
    addNotification(
      notification,
      this.notificationList,
      (cb) => (this.notificationList = cb)
    );
  }

  newSearchHandler() {
    this.rma = {} as Rma;
    this.rmaCaNumber = "";
  }

  logoutHandler() {
    localStorage.token = "";
    this.$router.push("login");
  }

  fetchRma(caNumber: string) {
    this.loading = false;
    fetch(`${process.env.VUE_APP_BASE_URL}pickup/admin/${caNumber}`, {
      headers: {
        Authorization: `Bearer ${this.token}`,
        "Content-Type": "application/json",
      },
    })
      .then(async (response) => {
        if (response.ok) {
          return response.json();
        } else {
          if (
            !response.headers.get("Content-Type")?.includes("application/json")
          )
            throw new Error("Request Error: " + response.statusText);
          const data = await response.json();
          throw new Error("Error: " + data.message);
        }
      })
      .then((responseJson) => {
        this.rma = responseJson;
      })
      .catch((error) => {
        addNotification(
          { message: error.message, type: "error" },
          this.notificationList,
          (cb) => (this.notificationList = cb)
        );
      })
      .finally(() => {
        this.loading = false;
      });
  }
}
