import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-42c34c8c"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "dashboard" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Header = _resolveComponent("Header")!
  const _component_Form = _resolveComponent("Form")!
  const _component_ErrorList = _resolveComponent("ErrorList")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_Header),
    _createVNode(_component_Form, { onSendNotification: _ctx.notificationHandler }, null, 8, ["onSendNotification"]),
    _createVNode(_component_ErrorList, { errorList: _ctx.notificationList }, null, 8, ["errorList"])
  ]))
}