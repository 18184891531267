
import { Options, Vue } from "vue-class-component";

import Form from "@/components/Form.vue";
import Header from "@/components/Header.vue";
import ErrorList from "@/components/ErrorList.vue";

import { addNotification, Notification } from "@/utils/NotificationHandler";

@Options({
  components: {
    Header,
    Form,
    ErrorList,
  },
})
export default class Home extends Vue {
  private index = 0;
  private notificationList: Notification[] = [];

  notificationHandler(notification: Notification) {
    addNotification(
      notification,
      this.notificationList,
      (cb) => (this.notificationList = cb)
    );
  }
}
