export const put = (url: string, authToken: string, body?: BodyInit) => {
  return new Promise((resolve, reject) => {
    fetch(`${process.env.VUE_APP_BASE_URL}${url}`, {
      method: "PUT",
      cache: "no-cache",
      headers: {
        Authorization: `Bearer ${authToken}`,
        "Content-Type": "application/json",
      },
      body,
    })
      .then(async (response) => {
        if (response.ok) {
          return response.json();
        } else {
          if (
            !response.headers.get("Content-Type")?.includes("application/json")
          )
            throw new Error("Request Error: " + response.statusText);
          const data = await response.json();
          throw new Error("Error: " + data.message);
        }
      })
      .then((responseJson) => {
        resolve(responseJson);
      })
      .catch((error) => {
        reject(error);
      });
  });
};
