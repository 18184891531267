import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-448eb278"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "date-input" }
const _hoisted_2 = { class: "screen-wrapper" }
const _hoisted_3 = { class: "screen" }
const _hoisted_4 = { class: "month-title" }
const _hoisted_5 = ["onClick"]
const _hoisted_6 = { class: "dayLabel" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.pickupDays, (month, monthIndex) => {
          return (_openBlock(), _createElementBlock("div", {
            class: "month",
            key: `month-${monthIndex}`
          }, [
            _createElementVNode("span", _hoisted_4, _toDisplayString(_ctx.getMonth(_ctx.pickupDays[monthIndex][0].javaDate.getMonth())), 1),
            _createElementVNode("table", null, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(month, (day, dayIndex) => {
                return (_openBlock(), _createElementBlock("tr", {
                  class: _normalizeClass(["dayItem", day.selected ? 'selected' : '']),
                  key: day.id,
                  onClick: ($event: any) => (_ctx.handleClickedDate(day, monthIndex, dayIndex))
                }, [
                  _createElementVNode("td", _hoisted_6, _toDisplayString(_ctx.getDay(day.javaDate.getDay())), 1),
                  _createElementVNode("td", null, _toDisplayString(day.javaDate.getDate()) + " " + _toDisplayString(_ctx.getMonth(day.javaDate.getMonth())) + " " + _toDisplayString(day.javaDate.getFullYear()), 1)
                ], 10, _hoisted_5))
              }), 128))
            ])
          ]))
        }), 128))
      ])
    ])
  ]))
}