import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, vModelText as _vModelText, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-cb2ab7ee"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "admin-dashboard" }
const _hoisted_2 = { class: "centered" }
const _hoisted_3 = { class: "subtitle" }
const _hoisted_4 = {
  key: 0,
  class: "form-wrapper"
}
const _hoisted_5 = { for: "caNumber-input" }
const _hoisted_6 = ["value"]
const _hoisted_7 = { key: 1 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Header = _resolveComponent("Header")!
  const _component_RMADisplay = _resolveComponent("RMADisplay")!
  const _component_ErrorList = _resolveComponent("ErrorList")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_Header),
    _createElementVNode("button", {
      class: "button",
      onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.logoutHandler && _ctx.logoutHandler(...args)))
    }, _toDisplayString(_ctx.lang.logout), 1),
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("h1", null, [
        _createTextVNode(_toDisplayString(_ctx.lang.header.title) + " ", 1),
        _createElementVNode("span", _hoisted_3, _toDisplayString(_ctx.lang.header.subtitle), 1)
      ]),
      (!_ctx.rma.CANumber)
        ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
            _createElementVNode("form", {
              onSubmit: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.checkForm && _ctx.checkForm(...args))),
              autocomplete: "off"
            }, [
              _createElementVNode("label", _hoisted_5, _toDisplayString(_ctx.lang.form.caNumberField), 1),
              _withDirectives(_createElementVNode("input", {
                type: "text",
                name: "caNumber",
                id: "caNumber-input",
                "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.rmaCaNumber) = $event))
              }, null, 512), [
                [_vModelText, _ctx.rmaCaNumber]
              ]),
              _createElementVNode("input", {
                class: "button",
                type: "submit",
                value: _ctx.lang.form.submitButton
              }, null, 8, _hoisted_6)
            ], 32)
          ]))
        : _createCommentVNode("", true),
      (_ctx.rma.CANumber)
        ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
            _createElementVNode("button", {
              class: "button",
              onClick: _cache[3] || (_cache[3] = 
//@ts-ignore
(...args) => (_ctx.newSearchHandler && _ctx.newSearchHandler(...args)))
            }, _toDisplayString(_ctx.lang.result.back), 1),
            _createElementVNode("h2", null, _toDisplayString(_ctx.lang.result.resultsHeader), 1),
            _createVNode(_component_RMADisplay, {
              rma: _ctx.rma,
              onSendNotification: _ctx.notificationPassthrough,
              onResetForm: _ctx.newSearchHandler
            }, null, 8, ["rma", "onSendNotification", "onResetForm"])
          ]))
        : _createCommentVNode("", true)
    ]),
    _createVNode(_component_ErrorList, { errorList: _ctx.notificationList }, null, 8, ["errorList"])
  ]))
}