import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-8989242c"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "restricted" }
const _hoisted_2 = { class: "centered" }
const _hoisted_3 = { class: "subtitle" }
const _hoisted_4 = { class: "subtitle" }
const _hoisted_5 = { href: "login" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Header = _resolveComponent("Header")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_Header),
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("h1", null, [
        _createTextVNode(_toDisplayString(_ctx.lang.header.title) + " ", 1),
        _createElementVNode("span", _hoisted_3, _toDisplayString(_ctx.lang.header.subtitle), 1)
      ]),
      _createElementVNode("p", null, _toDisplayString(_ctx.lang.description), 1),
      _createElementVNode("div", null, [
        _createElementVNode("span", _hoisted_4, [
          _createTextVNode(_toDisplayString(_ctx.lang.adminLogin.prefix) + " ", 1),
          _createElementVNode("a", _hoisted_5, _toDisplayString(_ctx.lang.adminLogin.suffix), 1),
          _createTextVNode(".")
        ])
      ])
    ])
  ]))
}