
import { Options, Vue } from "vue-class-component";

interface Notification {
  id: number;
  message: string;
  type: "error" | "success" | "info";
}

@Options({
  props: ["errorList"],
})
export default class ErrorList extends Vue {
  errorList!: Notification[];

  get lang() {
    return this.$ml.pack?.texts.generic;
  }
}
