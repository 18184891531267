import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";

import { Multilanguage } from "./utils/Multilanguage";

const multilanguage = new Multilanguage();

const app = createApp(App).use(store).use(router);

app.config.globalProperties.$ml = multilanguage;
app.mount("#app");

declare module "@vue/runtime-core" {
  interface ComponentCustomProperties {
    $ml: Multilanguage;
  }
}
