import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-7bbeee4b"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "existing-editable" }
const _hoisted_2 = { class: "centered" }
const _hoisted_3 = { class: "subtitle" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Header = _resolveComponent("Header")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_Header),
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("h1", null, [
        _createTextVNode(_toDisplayString(_ctx.lang.header.title) + " ", 1),
        _createElementVNode("span", _hoisted_3, _toDisplayString(_ctx.lang.header.subtitle), 1)
      ]),
      _createElementVNode("p", null, _toDisplayString(_ctx.lang.description), 1),
      _createElementVNode("button", {
        onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.backToHome && _ctx.backToHome(...args))),
        class: "button"
      }, _toDisplayString(_ctx.lang.button), 1)
    ])
  ]))
}