
import { Options, Vue } from "vue-class-component";
import axios from "axios";

import DateInput from "./DateInput.vue";

// Google Captcha type
declare let grecaptcha: {
  ready: (callback: () => void) => Promise<boolean>;
  execute: (token: string, options: { action: string }) => Promise<string>;
};

@Options({
  emits: ["sendNotification"],
  components: {
    DateInput,
  },
})
export default class Form extends Vue {
  private rmaId = "";
  private countryCode = "";
  private rmaDate = new Date(0);
  private rmaPickupToday = false;
  private rmaDayPartRaw = "0";

  mounted() {
    if (!this.rmaToken || this.rmaToken.length == 0)
      this.$router.push("/restricted");

    axios
      .get(`https://pua.cambridgeaudioservice.eu/pickup/${this.rmaToken}`)
      .then((response) => {
        if (response.data.editState == "CLOSED") {
          this.$router.push("/existing");
        } else {
          if (
            response.data.editState == "EXISTING" &&
            this.wasRedirected == false
          )
            this.$router.push({
              path: "/existing-editable",
              query: { token: this.rmaToken },
            });
          this.countryCode = response.data.countryCode;
          this.rmaId = response.data.cambridgeId;
        }
      })
      .catch((error) => {
        this.$emit("sendNotification", {
          type: "error",
          message:
            error.response?.data.message ??
            "Can't connect to Server, please contact Hanex Service",
        });
      });
  }

  get rmaDayPart() {
    return parseInt(this.rmaDayPartRaw) ?? 0;
  }

  get rmaToken() {
    return (this.$route?.query.token as string) ?? "";
  }

  get wasRedirected(): boolean {
    return this.$route?.query.fromRedirect == "98256341";
  }

  get lang() {
    return this.$ml.pack?.texts.home;
  }

  setRmaDate(date: Date) {
    this.rmaDate = date;
  }

  passNotification(payload: unknown) {
    this.$emit("sendNotification", payload);
  }

  async formSubmit(event: Event) {
    event.preventDefault();

    grecaptcha.ready(() => {
      grecaptcha
        .execute("6LcD9y4aAAAAALIkjQHBJAgY-ovIi29HLbY-2MM1", {
          action: "submit",
        })
        .then(async (gToken) => {
          await this.executeRequest(gToken);
        })
        .catch(() => {
          this.$emit("sendNotification", {
            type: "error",
            message: "Captcha Failed, are you a robot?",
          });
        });
    });
  }

  async executeRequest(token: string) {
    axios
      .post(
        `https://pua.cambridgeaudioservice.eu/pickup/addDate/${this.rmaToken}`,
        {
          captchaToken: token,
          rmaPickupDate: this.rmaDate.getTime(),
          rmaPickupToday: this.rmaPickupToday,
          rmaDayPart: this.rmaDayPart,
        }
      )
      .then(() => {
        this.$router.push("/received");
      })
      .catch((error) => {
        if (error.response?.data?.message) {
          this.$emit("sendNotification", {
            type: "error",
            message: `${error.response?.data?.message} c${error.response?.data?.statusCode}`,
          });
        } else {
          this.$emit("sendNotification", {
            type: "error",
            message: "Can't connect to Server, please contact Hanex Service",
          });
        }
      });
  }
}
