let index = 0;

export interface Notification {
  id?: number;
  message: string;
  type: "error" | "success" | "info";
}

export const removeLatest = (
  timeout: number,
  notificationList: Notification[]
): Promise<Notification[]> => {
  return new Promise((resolve) => {
    setTimeout(() => {
      notificationList.shift();
      resolve(notificationList);
    }, timeout);
  });
};

export const addNotification = (
  notification: Notification,
  notificationList: Notification[],
  onTimeOut: (returnList: Notification[]) => void
) => {
  notificationList.push({
    id: index++,
    message: notification.message,
    type: notification.type,
  });

  removeLatest(4000, notificationList).then((list) => {
    onTimeOut(list);
  });
};
