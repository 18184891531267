
import { Options, Vue } from "vue-class-component";

import Header from "@/components/Header.vue";

@Options({
  components: {
    Header,
  },
})
export default class ExistingEditable extends Vue {
  backToHome() {
    this.$router.push({
      path: "/",
      query: { token: this.rmaToken, fromRedirect: 98256341 },
    });
  }

  get rmaToken() {
    return (this.$route?.query.token as string) ?? "";
  }

  get lang() {
    return this.$ml.pack?.texts.existingEditable;
  }
}
