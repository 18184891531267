
import { Options, Vue } from "vue-class-component";

import axios from "axios";

interface RawPickday {
  id: number;
  date: {
    js: string;
    day: string;
    date: number;
    month: string;
    year: number;
  };
}

interface Pickday {
  id: number;
  javaDate: Date;
  selected: boolean;
}

type Month = Pickday[];

type Year = Month[];

@Options({
  emits: ["selected", "sendNotification"],
  props: {
    countryCode: String,
  },
})
export default class DateInput extends Vue {
  private pickupDays: Year = [];
  private countryCode!: string;

  get monthNames() {
    return this.$ml.pack?.texts.home.form.dateInput.monthNames;
  }

  get dayNames() {
    return this.$ml.pack?.texts.home.form.dateInput.dayNames;
  }

  fixedLength(number: number): string {
    if (number < 10) return "0" + number;
    else return number.toString();
  }

  getMonth(index: number): string {
    return this.monthNames[index];
  }

  getDay(index: number): string {
    return this.dayNames[index];
  }

  handleClickedDate(
    date: Pickday,
    monthIndex: number,
    dateIndex: number
  ): void {
    this.pickupDays.forEach((month) => {
      month.map((day) => {
        day.selected = false;
      });
    });
    this.pickupDays[monthIndex][dateIndex].selected = true;

    this.$emit("selected", date.javaDate);
  }

  async mounted() {
    try {
      const response = await axios.get(
        `https://hxw.hanexservice.eu/api/rma/transportDays/${this.countryCode}?length=14`
      );

      const dates: Pickday[] = [];

      //Create a new PickupDay from result list
      response.data.forEach((date: RawPickday) => {
        const dateObject: Pickday = {
          id: date.id,
          javaDate: new Date(date.date.js),
          selected: false,
        };
        dates.push(dateObject);
      });

      // Add each day to its corosponding month
      const fixedMonths: Year = [
        [],
        [],
        [],
        [],
        [],
        [],
        [],
        [],
        [],
        [],
        [],
        [],
      ];

      dates.forEach((date) => {
        fixedMonths[date.javaDate.getMonth()].push(date);
      });

      // Filter out empty months
      const filledMonths: Year = [];
      fixedMonths.forEach((month) => {
        if (month.length > 0) filledMonths.push(month);
      });

      this.pickupDays = filledMonths;
    } catch {
      this.$emit("sendNotification", {
        type: "error",
        message: "Server Error, Contact Hanex Service.",
      });
    }
  }
}
