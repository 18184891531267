
import { Options, Vue } from "vue-class-component";
import { Rma } from "@/types/rma";
import { put } from "@/utils/Fetch";

@Options({
  props: {
    rma: Object,
  },
  emits: ["sendNotification", "resetForm"],
})
export default class RMADisplay extends Vue {
  private rma!: Rma;
  private renewed = false;
  private countdown = 4;

  get caNumber() {
    return this.rma.CANumber ?? "Error";
  }

  get isRenewable() {
    return this.rma.renewable;
  }

  get isRenewableText() {
    return this.isRenewable
      ? this.lang.result.display.renewableHeader
      : this.lang.result.display.notRenewableHeader;
  }

  get token() {
    return localStorage.token;
  }

  get lang() {
    return this.$ml.pack?.texts.adminDashboard;
  }

  renewRequestClick() {
    this.fetchRenewRequest(this.caNumber);
  }

  renewFinishedHandler() {
    this.renewed = true;
    this.countdown = 4;

    const timeout = setInterval(() => {
      this.countdown--;
      if (this.countdown <= 0) {
        clearInterval(timeout);
        this.$emit("resetForm");
      }
    }, 1000);
  }

  fetchRenewRequest(caNumber: string) {
    put(`pickup/admin/${caNumber}`, this.token)
      .then(() => {
        this.renewFinishedHandler();
      })
      .catch((error) => {
        this.$emit("sendNotification", {
          message: error.message,
          type: "error",
        });
      });
  }

  cancelHandler() {
    put(`pickup/admin/${this.caNumber}/cancel`, this.token)
      .then(() => {
        this.$emit("sendNotification", {
          message: this.lang.result.display.canceledRequest,
          type: "info",
        });
      })
      .catch((error) => {
        this.$emit("sendNotification", {
          message: error.message,
          type: "error",
        });
      });
  }

  printLabelHandler() {
    put(`pickup/admin/${this.caNumber}/print`, this.token)
      .then(() => {
        this.$emit("sendNotification", {
          message: this.lang.result.display.printRequest,
          type: "info",
        });
      })
      .catch((error) => {
        this.$emit("sendNotification", {
          message: error.message,
          type: "error",
        });
      });
  }
}
