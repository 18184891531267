import { LanguagePack } from "./types/LanguagePack";

const languagePack: LanguagePack = {
  isoCode: "en",
  texts: {
    generic: {
      errorPrefix: "Error: ",
    },
    adminDashboard: {
      header: {
        title: "Admin",
        subtitle: "dashboard",
      },
      logout: "Logout",
      form: {
        caNumberField: "CA-Number:",
        submitButton: "Search",
      },
      result: {
        back: "Back to search",
        resultsHeader: "Results:",
        display: {
          header: "Number:",
          notRenewableDescription:
            "This could because it has active errors, or that is is already being processed.",
          notRenewableHeader: "This RMA is not renewable",
          renewButton: "Renew Request",
          printButton: "Print and Post Label",
          cancelButton: "Cancel Request",
          renewableHeader: "This RMA is renewable",
          renewed: {
            prefix: "Rma Is renewed, you will be sendback to searching in",
            suffix: "seconds.",
          },
          canceledRequest: "Request canceled!",
          printRequest: "Print request send!",
        },
      },
    },
    login: {
      header: {
        title: "Administrator",
        subtitle: "login",
      },
      form: {
        emailField: "Email",
        passwordField: "Password",
        submitButton: "Login",
      },
    },
    existing: {
      header: {
        title: "Request",
        subtitle: "already exists!",
      },
      description:
        "We already have a valid UPS Pickup date! You can close this page now.",
    },
    existingEditable: {
      header: {
        title: "Request",
        subtitle: "already exists!",
      },
      description:
        "We already have a valid UPS Pickup date, but you can still resubmit the form if you want to change the date.",
      button: "Changed Pickup Date",
    },
    received: {
      header: {
        title: "Request",
        subtitle: "received!",
      },
      description:
        "We have everything we need! You will receive a confirmation by email. You can close this page now.",
    },
    restricted: {
      header: {
        title: "Invalid",
        subtitle: "request!",
      },
      description:
        "This request has expired or was invalid in the first place.",
      adminLogin: {
        prefix: "Are you a admin that is trying to login? Click",
        suffix: "here",
      },
    },
    home: {
      header: {
        title: "Book a",
        subtitle: "UPS Pickup",
      },
      form: {
        rmaHeader: "Your RMA:",
        rmaDateField: "Pickup Date*",
        dateInput: {
          dayNames: [
            "Sunday",
            "Monday",
            "Tuesday",
            "Wednesday",
            "Thursday",
            "Friday",
            "Saturday",
          ],
          monthNames: [
            "January",
            "February",
            "March",
            "April",
            "May",
            "June",
            "July",
            "August",
            "September",
            "October",
            "November",
            "December",
          ],
        },
        dayPart: {
          header:
            "We can try to narrow the pickup to a daypart. ( All day will be selected if the chosen daypart is not available )",
          allday: "All Day",
          morning: "Morning",
          afternoon: "Afternoon",
          subtext:
            "This is not a guarantee, if the selected time is not avaiable, we will fallback to all day.",
        },
        pickupToday: {
          header:
            "We can try to book the pickup for today, if this fails we will use the selected day and daypart.",
          checkbox: "Try to get the pickup today",
          subtext:
            "This is not a guarantee, if we can't pickup today, we will fallback to the selected date.",
        },
      },
      loading: {
        header: {
          title: "Loading",
          subtitle: "request...",
        },
        description:
          "If this takes more then a minute, please refresh the page.",
      },
    },
  },
};

export default languagePack;
