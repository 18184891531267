import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";
import Home from "@/views/Home.vue";
import Received from "@/views/Received.vue";
import Restricted from "@/views/Restricted.vue";
import Existing from "@/views/Existing.vue";
import ExistingEditable from "@/views/ExistingEditable.vue";
import AdminDashboard from "@/views/AdminDashboard.vue";
import Login from "@/views/Login.vue";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    name: "Dashboard",
    component: Home,
  },
  {
    path: "/received",
    name: "Received",
    component: Received,
  },
  {
    path: "/restricted",
    name: "Restricted",
    component: Restricted,
  },
  {
    path: "/existing",
    name: "Existing",
    component: Existing,
  },
  {
    path: "/existing-editable",
    name: "Existing Editable",
    component: ExistingEditable,
  },
  {
    path: "/dashboard",
    name: "Admin Dashboard",
    component: AdminDashboard,
  },
  {
    path: "/login",
    name: "Login",
    component: Login,
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
