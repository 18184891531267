
import { Options, Vue } from "vue-class-component";
import { Watch } from "vue-property-decorator";

import Header from "@/components/Header.vue";
import ErrorList from "@/components/ErrorList.vue";

import { addNotification, Notification } from "@/utils/NotificationHandler";

@Options({
  components: {
    Header,
    ErrorList,
  },
})
export default class Login extends Vue {
  private loading = true;
  private notificationList: Notification[] = [];

  private email = "";
  private password = "";
  private token = "";

  get lang() {
    return this.$ml.pack?.texts.login;
  }

  mounted() {
    if (localStorage.token) this.$router.push("dashboard");
  }

  checkForm(event: Event) {
    event.preventDefault();

    this.fetchToken(this.email, this.password);
  }

  fetchToken(email: string, password: string) {
    this.loading = false;
    fetch(`${process.env.VUE_APP_BASE_URL}user/login`, {
      method: "POST",
      cache: "no-cache",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        email,
        password,
      }),
    })
      .then(async (response) => {
        if (response.ok) {
          return response.json();
        } else {
          if (
            !response.headers.get("Content-Type")?.includes("application/json")
          )
            throw new Error("Request Error: " + response.statusText);
          const data = await response.json();
          throw new Error("Error: " + data.message);
        }
      })
      .then((responseJson) => {
        this.token = responseJson.token;
        this.$router.push("dashboard");
      })
      .catch((error) => {
        addNotification(
          { message: error.message, type: "error" },
          this.notificationList,
          (cb) => (this.notificationList = cb)
        );
      })
      .finally(() => {
        this.loading = false;
      });
  }

  @Watch("token")
  onTokenChange(newToken: string) {
    localStorage.token = newToken;
  }
}
